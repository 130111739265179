@import "modules/responsive-type.scss";
@import url('https://fonts.googleapis.com/css?family=Sunflower:300,700');

$primary: #c62a51;
/* MAIN COLOR */

$secondary: #ffac99;
/* SECONDARY COLOR */

$blk: #000;
$wht: #fff;
// Set your maximum and minimum screen sizes.
$min_width: 320px;
$max_width: 1920px;
// Font sizes will range between the $min_font and $max_font.
$min_font: 15px;
$max_font: 22px;
// Change these values to change the "scale" between different headers
// (h1,h2,h3, etc.). Larger numbers = larger font-sizes.
$mod_1: 1.2; // mobile
$mod_2: 1.5; // desktop
html,
body {
    height: 100%;
    font-family: 'Sunflower',  sans-serif;

}

body {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
}

html {
    @include fluid-type($min_width, $max_width, $min_font, $max_font);
}

p {
    font-size: 1rem;
}

h1 {
    font-size: $mod_1*$mod_1*$mod_1*$mod_1 *1rem;
    @include fluid-type($min_width, $max_width, $mod_1*$mod_1*$mod_1 *$min_font, $mod_2*$mod_2*$mod_2 *$min_font);
}

h2 {
    font-size: $mod_1*$mod_1*$mod_1 *1rem;
    @include fluid-type($min_width, $max_width, $mod_1*$mod_1*$mod_1 *$min_font, $mod_2*$mod_2*$mod_2 *$min_font);
}

h3 {
    font-size: $mod_1*$mod_1 *1rem;
    @include fluid-type($min_width, $max_width, $mod_1*$mod_1 *$min_font, $mod_2*$mod_2 *$min_font);
}

a,
a:hover,
a:focus,
a:active {
    outline: 0 !important;
    text-decoration: none;
}

// .flash {
//     display: none;
// }
q,
blockquote {
    quotes: "“" "”";
}

.btn-primary {
    background-color: $primary;
    border: none;
    font-size: .8rem;
    padding: .5rem 1.5rem;
    text-transform: uppercase;
    transition: 0.7s ease all;
    letter-spacing: 1px;
    &:hover {
        background-color: $secondary;
    }
}

/* Change autocomplete styles in WebKit */

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    border: 1px solid $wht;
    -webkit-text-fill-color: $wht;
    -webkit-box-shadow: 0 0 0px 1000px $blk inset;
    transition: background-color 5000s ease-in-out 0s;
}

.logo {
    max-height: 100px;
    @media(max-width: 991px) {
        max-height: 70px;
    }
    @media(max-width:667px) {
        max-height: 50px;
    }
}

nav.navbar {
    z-index: 1000;
    border: none;
    .navbar-nav {
        .nav-item {
            .nav-link {
                text-align: center;
                align-items: center;
                color: $blk;
                font-size: .7em;
                text-transform: uppercase;
                padding: .5em 1em;
                border-radius: 4px;
                transition: 0.3s ease all;
                &:hover {
                    background: $primary;
                    color: $wht;
                    @media (max-width: 1024px) {
                        background: transparent;
                        color: $blk;
                        outline: 0;
                    }
                }
                &:focus,
                &:active {
                    background: transparent;
                    color: $blk;
                    outline: 0;
                }
                @media (max-width: 767px) {
                    margin-top: 0;
                    padding: 8px;
                    // display: inline-block;
                    text-align: center;
                }
            }
        }
    }
}

// login modal start 
.modal-header,
.modal-footer {
    background: $primary;
    color: $wht;
    h5 {
        text-align: center
    }
    .btn-primary,
    .btn {
        background-color: $primary;
        color: $wht;
        display: inline;
        text-align: center;
        outline: none;
        text-decoration: none;
    }
}

.modal-body {
    input,
    label {
        width: 100%;
        text-align: center
    }
    .send {
        margin-top: 1em;
        outline: none;
        text-decoration: none;
    }
    .btn-primary,
    .btn {
        background-color: $primary;
        outline: none;
        text-decoration: none;
    }
}

// login modal  end 
/** LOGIN FORM **/

// @mixin btn {
//     background: $primary;
//     color: $wht;
//     font-size: 1.2em;
//     text-align: center;
//     transition: 0.7s ease all;
//     text-decoration: none;
//     &:hover {
//         background: $secondary;
//         color: $wht;
//     }
// }
// .btn-default {
//     @include btn;
// }
/** END LOGIN FORM **/

//section start
.delay-1 {
    animation-delay: .25s;
}

.delay-2 {
    animation-delay: .5s;
}

.delay-3 {
    animation-delay: .75s;
}

.delay-4 {
    animation-delay: 1s;
}

.box {
    background-color: rgba(0, 0, 0, .7);
    padding: 350px 0;
    @media (max-width: 1024px) {
        padding: 250px 0;
    }
    @media (max-width: 767px) {
        padding: 150px 0;
    }
    @media (max-width: 600px) {
        padding: 100px 0;
    }
}

.bg {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat; // background-attachment: fixed;
    @media(max-width: 1024px) {
        // background-attachment: scroll;
    }
}

.pd {
    padding: 120px 0;
    @media(max-width: 991px) {
        padding: 80px 0;
    }
    @media(max-width:767px) {
        padding: 40px 0;
    }
}

.first-slide {
    background-image: url("../img/banner.jpg");
}

.second-slide {
    background-image: url("../img/banner-1.jpg");
}

.third-slide {
    background-image: url("../img/banner-2.jpg");
}
@media(max-width:667px){
    .carousel-item {
        height: 20em;
    }
}
//section end
footer {
    padding: 50px 0px 10px;
    background: $primary;
    color: $wht;
    p {
        font-size: .6rem;
    }
    a {
        color: $wht;
        white-space: nowrap;
        &:hover {
            color: lighten($primary, 50%);
        }
    }
}

input#username,
input#password {
    width: 100%;
}

select {
    -moz-appearance: none;
    text-indent: 0.01px;
    text-overflow: '';
}

.top-pad {
    padding: 4em 2em;
    background: white;
    @media (max-width: 990px) {
        padding: 3em 1em;
    }
    a {
        color: $primary
    }
}

.terms,
.privacy {
    font-size: 1rem;
    & ol {
        li {
            font-size: 1rem;
        }
        & ol li {
            font-size: 0.85rem;
        }
    }
}